
#tooltip:empty {
  display: none;
}
#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  position: absolute;
  padding: 4px;
  margin: 8px;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  z-index: 9;
  pointer-events: none;
}

.control-panel {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  background: #55555d;
  top: 0;
  border: 1px solid #ffffff42;
  left: 0;
  margin: 12px;
  padding: 12px;
  color: #fff;
  z-index: 1;
}

label {
  display: inline-block;
  width: 150px;
}
.leaflet-container {
  height: 300px;
  width: 100%
}

#style-btn-MR {
  width: 100px !important;
  background-color: #ef3937;
  border-color: #ef3937;
  color: #ffff;
  margin:5px;
}

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|Monda:700&display=swap');

html body .app.flex-row.align-items-center {
  height: 100vh;
}

body {
  font-family: 'Montserrat', sans-serif;
}
.sidebar {
  background: #3a4149;
  border-right: 1px solid #23282c;
}

.app-header {
  background-color: #3a4149;
  border-bottom: 1px solid #23282c;
}

.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #b3b7bb;
  text-align: center;
}

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: #ff4645;
}
.sidebar .nav-link.active {
  background: #ef3937;
}
a {
  color: #ef3937;
  text-decoration: none;
  background-color: transparent;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #ef3937;
  }

}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 200px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.custom-control {
  min-height: 1.400rem;
  font-size: 13px;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  background-color: #333;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}

/* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* this will style the thumb, ignoring the track */
.breadcrumb {
  line-height: 16px;
  border-bottom: 1px solid #23282c;
  background-color: #3a4149;

}

.breadcrumb > li > a {
  color: #ef3937;
  font-size: 15px;
}

.breadcrumb-item.active {
  color: #fff;
}

/*estilo de paginacion */
#pagin {
  float: right;
  margin-left: 15px;
}

#pagin > .pagination > li > a {
  color: #fff;
  padding: 8px;
  font-weight: 400;
  border: 1px solid #ef3937;
  border-radius: 5px;
  background-color: #ef3937;
}

#pagin > .pagination > .active > a {
  z-index: 3;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background-color: #ef3937;
  border-color: #ef3937;
  cursor: default;
}

/*fin de paginacion */

//date picker

.DayPickerInput > input {
  text-align: center;
  width: 100%;
  line-height: 1.5;
  display: block;
  height: calc(1.2em + 0.70rem + 1px) !important;
  padding: 0.335rem 0.35rem;
  font-size: 12px;
  font-weight: bold;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  color: #ef3937;
}

.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
}

.InputFromTo .DayPicker-Day {
  border-radius: .2rem !important;
}

.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
  color: #333;
}

.DayPicker-Day {
  color: #333;
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

//FIN DATEPICKER

//FORM DEL FORMULARIO
.form-control {
   display: block;
   width: 100%;
   height: calc(1.2em + 0.70rem + 1px) !important;
   padding: 0.335rem 0.35rem;
   font-size: 13px;
   font-weight: 400;
   line-height: 1.5;
   color: #5c6873;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #e4e7ea;
   border-radius: 0.25rem;
   -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }



// FIN FORM FORMULARIO

/*ESTILO TABLE*/

.table {
  margin-bottom: 0px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 19px;
  -webkit-overflow-scrolling: touch;
}

.stylo-table {
  background-color: #3a4149d4;
  color: #ffff;
  border-color: black;
}

.table thead th {
  text-transform: uppercase;
  font-size: 12px;
  border-color: rgba(201, 202, 204, 0.56);
}

.table tbody td {
  font-size: 12px;
  border-color: rgba(201, 202, 204, 0.56);
}

.table-bordered td, .table-bordered th {
  border-color: rgba(201, 202, 204, 0.56);
}

/* fin ESTILO TABLE*/

.mia {
  width: 26px;
  height: 24px;
  margin-right: 14px;
}

.contenido-logo-resk {
  align-content: center;
}

//ESTILO BNTO
.contenedor-padreButton {
  width: 100%;
  margin-top: 37px;
  margin-bottom: 15px;
  text-align: -webkit-center;
}
.content-btnFormulario {
  width: 15%;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: bold;
  border-radius: 3px;
  display: flex;
}
//FIN ESTILO BTN

//MODAL
.modal-content{
  background-color: #393f46;
  color: #ffffff;
}


// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }

}
